import store from "@/store/app";
import api from "@/shared/services/api";
import { SyncUserInTabs } from "@/shared/services/BroadcastChannel";
import router from "@/router";
import { ClearStateAndStorage } from "@/shared/scripts/ClearStateAndStorage";
import { getGuestTemplates } from "@/shared/requests/TemplateRequests";
export default async function Logout() {
    store.commit("toggleOverlay", true);
    logoutRequest().finally(() => {
        store.commit("toggleOverlay", false);
        store.commit("calculator/clearParameters");
        store.commit("calculator/clearResult");
        getGuestTemplates();
    });
}
function logoutRequest() {
    return api.post(process.env.VUE_APP_LOGOUT_URL).then(() => {
        store.dispatch("getInfluence");
        SyncUserInTabs();
        ClearStateAndStorage();
        router.push({ name: "Login" }).then(() => { });
    });
}
