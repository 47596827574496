import api from "@/shared/services/api";
import store from "@/store/app";
export default async function getTopBanks() {
    return api
        .get(process.env.VUE_APP_BANKS_TOP)
        .then((res) => {
        if (res.data.status === "success") {
            store.commit("common/writeTopBanks", res.data.data);
        }
    })
        .catch((e) => {
        store.dispatch("openAlertError", e);
    });
}
