export const GetUid = () => {
    const isDev = process.env["VUE_APP_IS_DEV"] === "true";
    if (isDev) {
        return process.env.VUE_APP_UID;
    }
    else {
        const uid = document.getElementById("widgetConfig").dataset.uid;
        return uid ?? undefined;
    }
};
