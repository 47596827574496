export const GetWidgetToken = () => {
    const isDev = process.env["VUE_APP_IS_DEV"] === "true";
    if (isDev) {
        return process.env.VUE_APP_TOKEN;
    }
    else {
        const token = document.getElementById("widgetConfig").dataset.wid;
        return token ?? "";
    }
};
