var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"BSW-checkbox-input__wrapper",class:{
    'BSW-checkbox-input__wrapper_with-description':
      _vm.info !== undefined && _vm.info !== '' && !_vm.customActivator,
  },attrs:{"width":_vm.width,"height":_vm.height}},[_c('v-checkbox',{staticClass:"BSW-checkbox-input",class:[
      { 'BSW-checkbox-input__disabled': _vm.disabled },
      { 'BSW-checkbox-input__success': _vm.success },
      { 'BSW-checkbox-input__noneBorder': _vm.noneBorder },
    ],style:(_vm.styleCheckbox),attrs:{"hide-details":"","disabled":_vm.disabled,"success":_vm.success},on:{"click":_vm.checkbox},nativeOn:{"click":function($event){return _vm.changeCheckbox.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('label',{staticClass:"BSW-checkbox-input__label",class:{ disabled: _vm.disabled }},[(_vm.disabled && _vm.labelDisabled)?_c('span',[_vm._v(_vm._s(_vm.labelDisabled))]):(_vm.customActivator && _vm.info !== undefined && _vm.info !== '')?_c('span',{staticClass:"BSW-checkbox-input__label__custom-activator",on:{"click":function($event){$event.stopPropagation();_vm.customActivatorModal = true}}},[_vm._v(_vm._s(_vm.label))]):_c('span',{class:{ 'BSW-checkbox-input__label-nowrap': _vm.nowrap }},[_vm._v(_vm._s(_vm.label))])])]},proxy:true}]),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),(_vm.showDescription)?_c('InputDescription',{staticClass:"BSW-checkbox-input__description",attrs:{"content":_vm.info,"title":_vm.label,"custom-activator-modal":_vm.customActivatorModal,"custom-activator":_vm.customActivator},on:{"close":function($event){_vm.customActivatorModal = false}},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }