<template>
  <div class="BSW-widget-input-description">
    <div v-if="content !== '' && content !== undefined && !customActivator">
      <v-btn
        height="20"
        width="20"
        color="success"
        icon
        class="BSW-widget-input-description__btn"
        outlined
        @click="modal = true"
        >?</v-btn
      >
    </div>

    <DefaultModal
      max-width="800"
      :modal="modal || customActivatorModal"
      @close="close"
      hide-main-text
    >
      <template #title>{{ title }}</template>
      <template #text><div v-html="content"></div></template>
    </DefaultModal>
  </div>
</template>

<script>
export default {
  name: "InputDescription",
  props: {
    content: {
      type: String,
    },
    title: {
      type: String,
    },
    customActivatorModal: {
      type: Boolean,
    },
    customActivator: {
      type: Boolean,
    },
  },

  data() {
    return {
      modal: false,
    };
  },

  methods: {
    close() {
      this.modal = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.BSW-widget-input-description {
  &__btn {
    margin-top: -6px;
    border-radius: 5px;
  }
  &__eye:after {
    display: none;
  }
}
</style>
