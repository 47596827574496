export const getDefaultUserState = () => {
    return {
        userLogged: false,
        user: {
            id: null,
            name: "",
            surname: "",
            patronymic: "",
            photo: "",
            bonuses: 0,
            promoCodeOnSignUp: null,
            registeredEvent: [],
            chief: 0,
            participantCount: 0,
            alerts: {
                news: {
                    date: 0,
                    flag: false,
                },
            },
            account: null,
        },
        availableTimer: {
            message_free: "",
            message_payed: "",
            minutes: 0,
            time: 0,
            timer: false,
        },
        specialisations: [],
        profileInfo: {
            user: {
                id: null,
                username: "",
                status: 0,
                email: "",
            },
            profile: {
                name: "",
                surname: "",
                patronymic: "",
                phone: "",
                company: "",
                company_site: "",
                photo: null,
                company_logo: null,
                study_certificate: null,
                study_certificate_photo: null,
                study_certificate_date: null,
                study_certificate_school: null,
                hide_reward_user: null,
                hide_preferences_user: null,
                hide_reward_by_chief: null,
                hide_preferences_by_chief: null,
            },
            cardEmployee: {
                name: "",
                surname: "",
                patronymic: "",
                phone: "",
                email: "",
                bonus: 0,
                passport_file: "",
                vk: null,
                ok: null,
                youtube: null,
                insta: null,
                telegram: null,
                whatsapp: null,
                payment_date: 0,
            },
        },
        editableProfileInfo: {
            email: "",
            phone: "",
            vk: "",
            ok: "",
            telegram: "",
            youtube: "",
            insta: "",
            whatsapp: "",
            company: "",
            company_address: "",
            company_site: "",
            companyLogoFile: "",
            photo_file: "",
            study_certificate: "",
            study_certificate_school: "",
            study_certificate_date: "",
            study_certificate_file: [],
            job_title: "",
            qualification: "",
            specialisation_id: [],
            name: "",
            surname: "",
            patronymic: "",
            commission_share: null,
            region_id: 0,
            other: "",
            experience: 0,
            hide_reward_user: false,
            hide_preferences_user: false,
        },
        firstUserRequestLoading: false,
    };
};
export const state = getDefaultUserState();
