import axios from "axios";
import { GetUrl } from "@/shared/services/GetUrl";
import { GetWidgetToken } from "@/shared/services/GetWidgetToken";
import { ClearStateAndStorage } from "@/shared/scripts/ClearStateAndStorage";
import router from "../../router/index.js";
import store from "@/store/widget";
import { GetUid } from "@/shared/services/GetUid";
const devURL = process.env["VUE_APP_IS_WIDGET"] === "true"
    ? window.location.origin + "/widget"
    : window.location.origin + "/";
const api = axios.create({
    baseURL: process.env["VUE_APP_IS_DEV"] === "true"
        ? devURL
        : process.env["VUE_APP_BASE_URL"],
});
api.interceptors.request.use((config) => {
    const isWidget = process.env["VUE_APP_IS_WIDGET"] === "true";
    config.withCredentials = !isWidget;
    if (config.url) {
        config.url = GetUrl(config.url);
    }
    if (isWidget && config.headers) {
        config.headers.common["Api-Token"] = GetWidgetToken();
        const uid = GetUid();
        if (uid) {
            config.headers.common["Authorization"] = uid;
        }
        if (store.state.userToken) {
            config.headers.common["User-Token"] = store.state.userToken;
        }
    }
    return config;
});
api.interceptors.response.use((config) => {
    return config;
}, (error) => {
    if (error.response.status === 401) {
        if (process.env["VUE_APP_IS_WIDGET"] === "true") {
            store.commit("deleteUserToken");
        }
        else {
            router.push({ name: "Main" }).then(() => ClearStateAndStorage());
        }
    }
    return Promise.reject(error);
});
export default api;
