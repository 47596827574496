import { render, staticRenderFns } from "./BuyBonusesModal.vue?vue&type=template&id=af7d48c0&scoped=true&"
import script from "./BuyBonusesModal.vue?vue&type=script&lang=ts&"
export * from "./BuyBonusesModal.vue?vue&type=script&lang=ts&"
import style0 from "../MyBonuses.scss?vue&type=style&index=0&id=af7d48c0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af7d48c0",
  null
  
)

export default component.exports