export const getDefaultRootCommonState = () => {
    return {
        /** Текущая ширина экрана */
        window: null,
        isWidget: process.env["VUE_APP_IS_WIDGET"] === "true",
        infoModal: {
            openModal: false,
            text: "",
            type: "",
        },
        banksList: [],
        showUpdateInfoModal: false,
        topBanks: [],
        isRu: true,
    };
};
export const commonState = getDefaultRootCommonState();
